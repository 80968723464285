import React, {memo, useEffect, useState} from 'react'
import { EuiResizeObserver } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import parkActions from 'store/park/parkActions';
import styled from 'styled-components'
import { useBreakpoints } from 'utils/useBreakpoints';

const Wrapper = styled.div`
  position: ${({ $fixed }) => $fixed ? 'fixed' : 'static'};
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ $fixed }) => $fixed ? '1002' : 'auto'};;
`;

const Banner = styled.div`
  background-color: ${({ $backgroundColor, theme }) => $backgroundColor || theme.colors.colorEmptyShade};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${({ $clickable }) => $clickable ? 'pointer' : 'auto'};
  border: none;
  color: ${({ $textColor }) => $textColor};
  line-height: 24px;
  font-size: 16px;
  padding: 10px 24px;
  min-height: 48px;
  &:hover {
    text-decoration: underline;
  }
  @media ${({ theme }) => theme.media.phone} {
    font-size: 13px;
    line-height: 19px;
    padding: 12px 14px;
  }
`;

const PromoBanner = () => {
  const [promoFetching, setPromoFetching] = useState(null)
  const dispatch = useDispatch()
  const park = useSelector((state) => state.park)
  const parkPromo = park.promo
  const { message, backgroundColor, textColor, url } = parkPromo
  const { isMobile } = useBreakpoints()

  const parkDetailsData = park.details.data
  const parkID = parkDetailsData?.park_id
  const bankType = parkDetailsData?.bank_type
  const subscriptionStatus = parkDetailsData?.subscription_status

  const onClickBanner = () => url && window.open(url, '_blank')
  const hasParams = !!(parkID && bankType && subscriptionStatus)
  const fetchPromo = () => dispatch(parkActions.fetchPromo({ bankType, subscriptionStatus, parkID }))

  const onResize = ({ height }) => dispatch(parkActions.setPromoHeight({
    height: !isMobile ? height : 0
  }))

  useEffect(() => {
    if (!hasParams || promoFetching) {
      return
    }

    fetchPromo()

    const promoInterval = setInterval(() => {
      fetchPromo()
      clearInterval(promoInterval)
    }, 120000)

    setPromoFetching(true)
    return () => clearInterval(promoInterval)
  }, [parkID, bankType, subscriptionStatus])

  useEffect(() => {
    if (!message && hasParams) {
      onResize(0)
    }
  }, [message])

  if (!(backgroundColor && textColor && message)) {
    return null
  }

  return (
    <EuiResizeObserver onResize={onResize}>
      {(observerRef) => (
        <Wrapper
          $fixed={!isMobile}
        >
          <Banner
            ref={observerRef}
            $backgroundColor={backgroundColor}
            $textColor={textColor}
            $clickable={!!url}
            onClick={onClickBanner}
          >
            <span>
              {message}
            </span>
          </Banner>
        </Wrapper>
      )}
    </EuiResizeObserver>
  )
}

export default memo(PromoBanner)
