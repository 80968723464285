import React from 'react';
import styled from 'styled-components';
import icon from 'assets/images/cloud-cashbox-blue.svg'
import { IconBadgeCard, BaseCard } from 'components/Cards';
import { useRouter } from 'utils/useRouter';
import { ContentLoading } from 'components/Loadings';
import { useBreakpoints } from 'utils/useBreakpoints';
import { PrimaryButton } from 'components/Buttons';

const CardTemplate = styled(IconBadgeCard)`
  &.not-connected, &.blocked, &.access-denied {
    .cardBadge {
      color: ${({ theme }) => theme.colors.colorDanger};
      background-color: ${({ theme }) => theme.colors.dangerLighter};
    }
  }
  &.connected {
    .cardBadge {
      color: ${({ theme }) => theme.colors.greenDarken};
      background-color: ${({ theme }) => theme.colors.greenBackground};
    }
  }
`;

const cardOptions = {
  'connected': {
    badgeText: 'Подключена',
    buttonText: 'Подробнее'
  },
  'blocked': {
    badgeText: 'Отключена',
    buttonText: 'Подробнее'
  },
  'not-connected': {
    badgeText: 'Не подключена',
    buttonText: 'Подключить'
  },
  'access-denied': {
    badgeText: 'Доступ запрещен',
    buttonText: 'Подробнее',
    buttonDisabled: true
  }
}

export const CloudCashboxCard = (props) => {
  const { className, receiptCloudCashboxLoading, receiptCloudCashboxData, accessDenied } = props
  const router = useRouter()
  const { isMobile } = useBreakpoints()

  const cloudCashboxStatus = accessDenied
    ? 'access-denied'
    : receiptCloudCashboxData?.blocked
      ? 'blocked'
      : receiptCloudCashboxData
        ? 'connected'
        : 'not-connected'

  if (receiptCloudCashboxLoading) {
    return (
      <BaseCard
        className={className}
        topBase={<ContentLoading width='40' height='40' />}
        topSecondary={<ContentLoading height='18' width='66' />}
        title={<ContentLoading width='167' />}
        body={<ContentLoading height='12' width='260' lines={!isMobile ? '2' : '1'} />}
        footer={<ContentLoading height='40' />}
      />
    )
  }

  return (
    <CardTemplate
      className={className + ' ' + cloudCashboxStatus}
      badgeText={cardOptions[cloudCashboxStatus].badgeText}
      title='Онлайн касса'
      body='Автоматическая отправка чеков водителям'
      icon={icon}
      footer={
        <PrimaryButton
          fullWidth
          onClick={() => router.history.push(`/settings/cloud-cashbox`)}
          disabled={cardOptions[cloudCashboxStatus].buttonDisabled}
        >
          {cardOptions[cloudCashboxStatus].buttonText}
        </PrimaryButton>
      }
    />
  )
}

export default CloudCashboxCard