import React from 'react';
import styled from 'styled-components';
import RangeDatePicker from '../RangeDatePicker/RangeDatePicker'
import {
  DateWrapper,
  DateInput
} from '../RangeDatePicker/RangeDatePicker';

export const DefaultRangeDatePicker = styled(props => <RangeDatePicker {...props} />)`
  ${DateWrapper} {
    background-color: ${({ theme }) => theme.colors.formInputBackgroundLight};
    border: 1px solid ${({ theme }) => theme.colors.graySubduedLighter};
  }
  ${DateInput} {
    &:focus {
      background-color: ${({ theme }) => theme.colors.formInputBackgroundLight};
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.formInputBackgroundLight};
    }
  }
`;
