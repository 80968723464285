import React from "react";
import { subscriptionStatusTypes } from "store/subscription/model/subscriptionStatusTypes";
import { DangerButton, GreenButton } from "components/Buttons";
import {
  StyledSideBarDanger,
  StyledSideBarStatus,
} from "components/SideMenu/styles";

import crownDangerIcon from "assets/images/crown-danger.svg";
import crownGreenIcon from "assets/images/crown-green.svg";

export const cardOptions = {
  [subscriptionStatusTypes.ACTIVE]: {
    icon: crownGreenIcon,
    description: (date) =>
      date ? (
        <>
          <div>Подписка истекает {date}</div>
          <StyledSideBarStatus>Осталось 3 дня</StyledSideBarStatus>
        </>
      ) : (
        ""
      ),
    footer: (router) => (
      <GreenButton fullWidth size="s" onClick={router}>
        Подробнее
      </GreenButton>
    ),
  },
  [subscriptionStatusTypes.UNPAID]: {
    icon: crownDangerIcon,
    description: (date) =>
      date ? (
        <>
          <div>Подписка истекла {date}</div>
          <StyledSideBarDanger>Не оплачена</StyledSideBarDanger>
        </>
      ) : (
        ""
      ),
    footer: (router) => (
      <DangerButton fullWidth size="s" onClick={router}>
        Подробнее
      </DangerButton>
    ),
  },
  [subscriptionStatusTypes.BLOCKED]: {
    icon: crownDangerIcon,
    description: (date) =>
      date ? (
        <>
          <div>Подписка истекла {date}</div>
          <StyledSideBarDanger>Заблокирована</StyledSideBarDanger>
        </>
      ) : (
        ""
      ),
    footer: (router) => (
      <DangerButton fullWidth size="s" onClick={router}>
        Подробнее
      </DangerButton>
    ),
  },
  [subscriptionStatusTypes.EXPIRES]: {
    description: (date) =>
      date ? (
        <>
          <div>Подписка истекает {date}</div>
          <StyledSideBarStatus>Осталось 3 дня</StyledSideBarStatus>
        </>
      ) : (
        ""
      ),
    footer: (router) => (
      <DangerButton fullWidth size="s" onClick={router}>
        Подробнее
      </DangerButton>
    ),
  },
  [subscriptionStatusTypes.SUSPENDED]: {
    icon: crownDangerIcon,
    description: (date) =>
      date ? (
        <>
          <div>Подписка истекла {date}</div>
          <StyledSideBarDanger>Приостановлена</StyledSideBarDanger>
        </>
      ) : (
        ""
      ),
    footer: (router) => (
      <DangerButton fullWidth size="s" onClick={router}>
        Подробнее
      </DangerButton>
    ),
  },
};
