import React from 'react';
import styled from 'styled-components';
import RangeDatePicker from '../RangeDatePicker/RangeDatePicker'
import {
  DateWrapper,
  Icon,
  DateInput
} from '../RangeDatePicker/RangeDatePicker';

export const PrimaryRangeDatePicker = styled(props => <RangeDatePicker {...props} />)`
  ${DateWrapper} {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    .euiFormControlLayoutIcons {
      color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }
  ${Icon} {
    color: ${({ theme }) => theme.colors.primaryBlue};
  }
  ${DateInput} {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-weight: 500;
    &::placeholder  {
      color: ${({ theme }) => theme.colors.primaryBlue};
      font-weight: 500;    
    }
    &:focus {
      background-color: ${({ theme }) => theme.colors.primaryBackground};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.primaryBlue};
      background: ${({ theme }) => theme.colors.primaryBackground};
      -webkit-text-fill-color: ${({ theme }) => theme.colors.primaryBlue};
    }
  }
`;
