import { createContext, useEffect, useMemo, useState } from "react";
import {
  EMPTY_TOPIC_ID,
  EMPTY_TOPIC_OBJ,
  INFO_CENTER_STATE,
} from "components/InfoCenter/model/constants";
import {
  getPostsByTopic,
  getPostsGroupedByTopic,
  getTopics,
} from "components/InfoCenter/model/service";
import { mapParseTopicToObject } from "components/InfoCenter/model/mapper";
import { useRouter } from "utils/useRouter";

const useInfoCenterListPage = () => {
  const router = useRouter();

  const [loadingStatus, setLoadingStatus] = useState(INFO_CENTER_STATE.LOADING);
  const [initialParamId, setInitialParamId] = useState(EMPTY_TOPIC_ID);
  const [topics, setTopics] = useState([]);
  const [previewTopicSections, setPreviewTopicSections] = useState([]);

  const [detailTopicSection, setDetailTopicSection] = useState(null);
  const [currentTopicId, setCurrentTopicId] = useState(EMPTY_TOPIC_ID);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const selectTopicById = ({ id }) => {
    if (id === currentTopicId) {
      return;
    }

    setCurrentTopicId(id);
    if (id !== currentTopicId) {
      const _topicId = id !== EMPTY_TOPIC_ID ? `/${id}` : "";
      router.push(`/info-center${_topicId}`);
    }

    if (id !== currentTopicId) {
      setCurrentPageIndex(0);
    }

    if (id === EMPTY_TOPIC_ID) {
      setDetailTopicSection(null);
      return;
    }

    const previewItem = previewTopicSections.find(
      ({ topic }) => topic.id === id,
    );
    setDetailTopicSection({
      ...previewItem,
      posts: [],
    });
  };

  const handlePaginationPageChange = (_pageIndex) => {
    setCurrentPageIndex(_pageIndex);
  };

  const sections = useMemo(() => {
    if (currentTopicId) {
      return [detailTopicSection];
    }

    return previewTopicSections;
  }, [previewTopicSections, detailTopicSection, currentTopicId]);

  useEffect(() => {
    if (currentTopicId === EMPTY_TOPIC_ID) {
      return;
    }

    fetchDetailTopicSection();
  }, [currentPageIndex || currentTopicId]);

  useEffect(() => {
    fetchPreviewTopicSection();
  }, []);

  useEffect(() => {
    if (initialParamId && loadingStatus === INFO_CENTER_STATE.LOADED) {
      selectTopicById({ id: initialParamId });
      setInitialParamId(undefined);
    }
  }, [initialParamId, loadingStatus]);

  const fetchPreviewTopicSection = async () => {
    try {
      setLoadingStatus(INFO_CENTER_STATE.INITIAL_LOADING);

      const topicsResult = await getTopics();
      if (topicsResult.length === 0) {
        setLoadingStatus(INFO_CENTER_STATE.EMPTY);
        return;
      }

      const topicList = topicsResult.map(mapParseTopicToObject);
      const groupedPostSections = await getPostsGroupedByTopic(topicsResult);

      setTopics(
        [
          EMPTY_TOPIC_OBJ,
          ...topicList.filter((_topic) => groupedPostSections.findIndex(_p => _p.topic.id === _topic.id) !== -1),
        ]);
      setPreviewTopicSections(groupedPostSections);

      setLoadingStatus(INFO_CENTER_STATE.LOADED);
    } catch (error) {
      console.error("INFO_CENTER [fetchInitialData]: ", error);
      setLoadingStatus(INFO_CENTER_STATE.ERROR);
    }
  };

  const fetchDetailTopicSection = async () => {
    setLoadingStatus(INFO_CENTER_STATE.LOADING);

    try {
      const currTopic = topics.find((_topic) => _topic.id === currentTopicId);
      const result = await getPostsByTopic(currTopic, {
        currentPageIndex,
        fetchCount: detailTopicSection.pageCount === undefined,
      });

      setDetailTopicSection((_dts) => ({
        ..._dts,
        posts: result.posts,
        pageCount: detailTopicSection.pageCount || result.pageCount,
      }));
      setLoadingStatus(INFO_CENTER_STATE.LOADED);
    } catch (error) {
      console.error("INFO_CENTER [fetchInitialData]: ", error);
      setLoadingStatus(INFO_CENTER_STATE.ERROR);
    }
  };

  return {
    sections,
    topics,

    currentTopicId,
    currentPageIndex,

    selectTopicById,
    handlePaginationPageChange,

    setInitialParamId,

    isInitialLoading: loadingStatus === INFO_CENTER_STATE.INITIAL_LOADING,
    isLoading: loadingStatus === INFO_CENTER_STATE.LOADING,
    isLoaded: loadingStatus === INFO_CENTER_STATE.LOADED,
    isError: loadingStatus === INFO_CENTER_STATE.ERROR,
    isEmpty: loadingStatus === INFO_CENTER_STATE.EMPTY,
  };
};

export const InfoCenterListPageContext = createContext({});
export const InfoCenterListPageProvider = ({ children }) => (
  <InfoCenterListPageContext.Provider value={useInfoCenterListPage()}>{children}</InfoCenterListPageContext.Provider>
);
