import React from 'react';
import styled from 'styled-components';
import {
  EuiIcon,
  EuiText, 
  EuiTitle,
} from '@elastic/eui';
import icon from 'assets/images/dashboard-lock-danger.svg'
import { useBreakpoints } from 'utils/useBreakpoints';

const Wrapper = styled.div`
  text-align: center;
`;

const Title = styled(EuiTitle)`
  margin: 33px 0 16px;
  @media ${({ theme }) => theme.media.phone} {
    margin: 17px 0 10px;
  }
`;

const Description = styled(EuiText)`
  color: ${({ theme }) => theme.colors.colorDarkShade};
  margin: 0 auto;
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Icon = styled(EuiIcon)``;

export const BaseAccessDenied = (props) => {
  const { className } = props
  const { isMobile } = useBreakpoints()
  return (
    <Wrapper
      className={className}
    >
      <Icon type={icon} size='original' />
      <Title size={!isMobile ? 'm' : 'xs'}>
        <h3>Доступ запрещен</h3>
      </Title>
      <Description>
        Для получения доступа обратитесь к администратору.
      </Description>
    </Wrapper>
  )
}