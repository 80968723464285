import { EuiIcon } from "@elastic/eui";
import React, { Fragment } from "react";
import { StyledSideBarListGroupItem } from "components/SideMenu/styles";
import { useSideBar } from "components/SideMenu/model/context";
import { useBreakpoints } from "utils/useBreakpoints";
import { SubMenu } from "components/SideMenu/components/SubMenu";

export const NavList = ({ navList }) => {
  const { openedMenu } = useSideBar();
  const { isMobile } = useBreakpoints();

  return (
    <>
      {navList.map((item, index) => {
        let icons = [...(item?.icons || [])];

        if (item?.menu && item?.path && !isMobile) {
          icons.push(
            <EuiIcon
              type="arrowRight"
              size="m"
              className={
                "menu-icon " + (openedMenu === item.path ? "rotate90" : "")
              }
            />,
          );
        }

        return (
          <Fragment key={index}>
            <StyledSideBarListGroupItem
              href={item?.href}
              target={item?.target}
              label={item.label}
              size={item.size}
              isActive={item.isActive}
              onClick={(event) => {
                !item?.href.includes("http") && event.preventDefault();
                item.onClick && item.onClick(item)();
              }}
              iconType={item?.iconType}
              icon={
                <>
                  {icons &&
                    icons.map((icon, iconIndex) => (
                      <React.Fragment key={iconIndex}>{icon}</React.Fragment>
                    ))}
                </>
              }
            />
            {!isMobile && openedMenu === item.path && (
              <SubMenu menu={item?.menu} />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
