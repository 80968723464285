import React from 'react';
import styled from 'styled-components';
import iconCross from 'assets/images/document-cross-purple.svg'
import iconCheck from 'assets/images/document-check-purple.svg'
import { IconBadgeCard } from 'components/Cards';
import { useBreakpoints } from 'utils/useBreakpoints';
import { useRouter } from 'utils/useRouter';
import { ContentLoading } from 'components/Loadings';
import { BaseCard } from 'components/Cards';
import { PurpleButton } from 'components/Buttons';


const CardTemplate = styled(IconBadgeCard)`
  &.not-connected, &.access-denied {
    .cardBadge {
      color: ${({ theme }) => theme.colors.colorDanger};
      background-color: ${({ theme }) => theme.colors.dangerLighter};
    }
  }
  &.connected {
    .cardBadge {
      color: ${({ theme }) => theme.colors.greenDarken};
      background-color: ${({ theme }) => theme.colors.greenBackground};
    }
  }
`;

const cardOptions = {
  'connected': {
    badgeText: 'Подключен',
    icon: iconCheck,
    buttonText: 'Редактировать',
  },
  'not-connected' : {
    badgeText: 'Не подключен',
    icon: iconCross,
    buttonText: 'Подключить',
  },
  'access-denied' : {
    badgeText: 'Доступ запрещен',
    icon: iconCross,
    buttonText: 'Подробнее',
    buttonDisabled: true
  }
}

export const EdgvrCard = (props) => {
  const { className, parkEdgvrApiKeyLoading, edgvrApiKey, openEdgvrData, accessDenied } = props
  const { isMobile } = useBreakpoints()
  const router = useRouter()

  const edgvrStatus = accessDenied
    ? 'access-denied'
    : edgvrApiKey
      ? 'connected'
      : 'not-connected'

  const primaryButtonEvent = () => isMobile || !edgvrApiKey ? router.history.push(`/settings/edgvr`) : openEdgvrData()

  if (parkEdgvrApiKeyLoading) {
    return (
      <BaseCard
        className={className}
        topBase={<ContentLoading width='40' height='40' />}
        topSecondary={<ContentLoading height='18' width='66' />}
        title={<ContentLoading width='167' />}
        body={<ContentLoading height='12' width='260' lines='2' />}
        footer={<ContentLoading height='40' />}
      />
    )
  }

  return (
    <CardTemplate
      className={className + ' ' + edgvrStatus}
      badgeText={cardOptions[edgvrStatus].badgeText}
      title='Сервис EDGVR'
      body='Удаленное заключение договоров с водителями'
      icon={cardOptions[edgvrStatus].icon}
      footer={
        <PurpleButton
          fullWidth
          onClick={primaryButtonEvent}
          disabled={cardOptions[edgvrStatus].buttonDisabled}
        >
          {cardOptions[edgvrStatus].buttonText}
        </PurpleButton>
      }
    />
  )
}

export default EdgvrCard