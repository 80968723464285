import { createContext, useContext, useState } from "react";

const SideBarContext = createContext({});

export const SideBarProvider = ({ children }) => {
  const [openedMenu, setOpenedMenu] = useState(null);

  return (
    <SideBarContext.Provider
      value={{
        openedMenu,
        setOpenedMenu,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBar = () => {
  return useContext(SideBarContext);
};
