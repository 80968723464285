import styled, { css } from "styled-components";
import { PrimaryShadeButton } from "components/Buttons";
import { EuiAvatar, EuiContextMenuItem, EuiIcon, EuiSwitch, EuiTitle } from "@elastic/eui";
import { PrimaryRangeDatePicker } from "components/RangeDatePickers";
import { BaseLoading } from "components/Loadings";
import NotFound from "components/NotFound/NotFound";
import EmployeesTable from "containers/EmployeePage/components/EmployeesTable";
import { BaseAccessDenied } from "components/AccessDenied";
import { BaseTabs } from "components/Tabs";

export const EmployeeHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;

    ${PrimaryShadeButton} {
        margin-left: auto;
    }

    @media ${({ theme }) => theme.media.phone} {
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 10px;
        flex-direction: column-reverse;

        ${PrimaryShadeButton} {
            margin-right: auto;
            margin-left: 0;
        }
    }
`;

export const EmployeeLogsHeader = styled(EmployeeHeader)`
    gap: 30px;
    display: flex;
    justify-content: space-between;
    @media ${({ theme }) => theme.media.phone} {
        margin: 0;
    }
`;

export const EmployeeLogsBottomHeader = styled(EmployeeHeader)`
    margin-bottom: 38px;
`;

export const EmployeeTitle = styled(EuiTitle)`
    margin-bottom: 40px;

    @media ${({ theme }) => theme.media.phone} {
        margin-bottom: 34px;
        font-size: 19px;
        line-height: 28px;
    }
`;

export const EmployeeFieldSearchWrapper = styled.div`
    width: 100%;
    max-width: 560px;
    flex: 1;

    .euiFormControlLayout {
        max-width: 100%;
    }

    .euiFormControlLayoutClearButton {
        margin-right: 24px;
    }

    input {
        padding-left: 12px;
        max-width: 100%;
    }

    .euiFormControlLayoutIcons {
        left: initial;
        right: 12px;
    }

    @media ${({ theme }) => theme.media.phone} {
        order: 3;
        flex-basis: 100%;
        margin: 16px 0 0;
    }
`;

export const EmployeeDatePicker = styled(PrimaryRangeDatePicker)`
    max-width: 285px;
    width: 100%;
    @media ${({ theme }) => theme.media.phone} {
        max-width: 190px;
        .startDatePickerWrapper {
            right: -79px;
        }
        margin-right: auto;
        margin-bottom: 10px;
    }
`;

export const EmployeeLogsLoading = styled(BaseLoading)`
    margin: 135px 20px;
    @media ${({ theme }) => theme.media.phone} {
        margin: 40px 20px;
    }
`;

export const EmployeeTableDate = styled.p`
    letter-spacing: -0.06em;
`;

export const EmployeeLogsNotFound = styled(NotFound)`
    padding: 40px 0;
    @media ${({ theme }) => theme.media.phone} {
        padding: 16px 0;
        br {
            display: none;
        }
    }
`;

export const EmployeeAction = styled(EuiContextMenuItem)`
    display: flex;
    align-items: center;
    padding: 12px 27px 12px 12px;
    font-weight: 500;

    &:focus, &:hover {
        background-color: transparent;
        text-decoration: none;
    }

    svg {
        margin-right: 10px;
    }
`;

export const EmployeeToggleBlockAction = styled(EmployeeAction)`
    color: ${({ theme, $color }) =>
            $color === "primary" && theme.colors.colorPrimary ||
            $color === "danger" && theme.colors.colorDanger
    };
`;

export const EmployeeListTable = styled(EmployeesTable)`
    margin-bottom: 48px;
    @media ${({ theme }) => theme.media.phone} {
        margin-bottom: 20px;
    }
`;

export const EmployeeColumnPhone = styled.span`
    font-weight: 600;
    @media ${({ theme }) => theme.media.phone} {
        font-weight: 400;
    }
`;

export const EmployeeColumnAvatar = styled(EuiAvatar)`
    margin-right: 16px;
    text-transform: uppercase;
`;

export const EmployeeHorizontal = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.colorLightShade};
`;

export const EmployeeEmptyStyles = css`
    margin: 163px 0 124px;

    .euiText {
        margin-bottom: 32px;
    }

    @media ${({ theme }) => theme.media.phone} {
        margin: 62px 0 80px;
    }
`;

export const EmployeeListNotFound = styled(NotFound)`
    ${EmployeeEmptyStyles}
`;

export const EmployeeAccessDenied = styled(BaseAccessDenied)`
    ${EmployeeEmptyStyles}
`;

export const EmployeeAlertIcon = styled(EuiIcon)`
    margin-right: 9px;
`;

export const EmployeeName = styled.div`
    margin-top: 25px;
`;

export const EmployeeAvatar = styled(EuiAvatar)`
    margin-right: 16px;
    text-transform: uppercase;
`;

export const EmployeeHeaderSwitch = styled(EuiSwitch)`
    @media ${({ theme }) => theme.media.phone} {
        order: 3;
        flex-basis: 100%;
        margin-bottom: 20px;
        margin-right: auto;
    }
`;

export const EmployeeLoading = styled(BaseLoading)`
    margin: 176px 0 124px;
    @media ${({ theme }) => theme.media.phone} {
        margin: 86px 0 80px;
    }
`;

export const EmployeeSidebarCards = styled.div`
    > * + * {
        margin-top: 20px;
    }

    @media ${({ theme }) => theme.media.phone} {
        margin-bottom: 20px;
    }
`;

export const EmployeeTabs = styled(BaseTabs)`
    margin-bottom: 28px;
    @media ${({ theme }) => theme.media.phone} {
        flex-direction: row;
        padding: 0;
        margin-bottom: 0;
        color: ${({ theme }) => theme.colors.colorPrimary};
        & > .euiTab.sc-eEVuZf.dyPyjr {
          justify-content: center;
          display: flex;
          border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightShade};
        }
        & > .euiTab-isSelected {
            background-color: ${props => props.theme.colors.colorEmptyShade};
            border-bottom: 2px solid ${({ theme }) => theme.colors.colorPrimary1} !important;
        }
        &:before {
            display: none;
        }
    }
`;
