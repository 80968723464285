import React, { useState } from 'react';
import styled from 'styled-components';
import {
  EuiIcon
} from '@elastic/eui';
import { RoundedCloseFlyout } from 'components/Flyouts';

const ChildrenWrapper = styled.div`
  overflow: auto;
  padding: 20px;
`;

const FixedButton = styled.div`
  position: fixed;
  right: 0;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.colors.primaryBackground};
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 2.7px 9px rgba(109, 152, 184, 0.13), 0px 9.4px 24px rgba(109, 152, 184, 0.09), 0px 21.8px 43px rgba(109, 152, 184, 0.08);
`;

const FixedButtonIcon = styled(EuiIcon)`
  color: ${props => props.theme.colors.colorPrimary};
  width: 19px;
  height: 19px;
  transform: translateX(13px);
`;

const FixedButtonCounter = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 13px;
  height: 13px;
  padding: 0 3px;
  background-color: ${props => props.theme.colors.colorPrimary};
  color: ${props => props.theme.colors.colorEmptyShade};
  border-radius: 13px;
  font-size: 9px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Flyout = styled(RoundedCloseFlyout)`
  background-color: ${({ theme }) => theme.colors.tableHover};
`;

const FixedIconFlyout = (props) => {
  const { children, badgeCounter } = props
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)

  let sidebar
  if (isSidebarVisible) {
    sidebar = (
      <Flyout
        maxWidth={292}
        outsideClickCloses
        onClose={() => setIsSidebarVisible(false)}>
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      </Flyout>
    );
  }
  return (
    <>
      <FixedButton
        onClick={() => setIsSidebarVisible(true)}
      >
        <FixedButtonIcon type='controlsHorizontal' />
        {badgeCounter && <FixedButtonCounter>{badgeCounter}</FixedButtonCounter>}
      </FixedButton>
      {sidebar}
    </>
  )
}

export default FixedIconFlyout