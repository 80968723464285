import { createSelector } from "reselect";

export const selectTotalCounters = createSelector(
  (state) => state.transactions,
  (transactions) => {
    if (transactions.loading) {
      return 0;
    }

    return (
      Object.values(transactions.counters).reduce(
        (total, amount) => total + amount
      ) || 0
    );
  }
);

export const selectParkDataForSideBar = createSelector(
  (state) => state.park,
  ({ details, credentials }) => {
    const detailsData = details.data;
    const credentialsData = credentials.data;

    return {
      parkDetailsLoading: details.loading,
      parkCredentialsLoading: credentials.loading,

      bankType: detailsData?.bank_type,
      subscriptionStatus: detailsData?.subscription_status,
      depositBalance: detailsData?.deposit_balance,

      balanceAmount: credentialsData?.balance?.amount,
    };
  }
);
