import {
  INFO_CENTER_BIND_TOPICS,
  MAX_INFO_CENTER_PAGE_ELEMENTS,
  MAX_PREVIEW_POSTS_COUNT,
} from "components/InfoCenter/model/constants";
import * as Parse from "parse";
import {
  mapParsePostToObject,
  mapParseTopicToObject,
} from "components/InfoCenter/model/mapper";

export const TOPIC_ENTITY_NAME = "INFO_CENTER_TOPIC";
export const POST_ENTITY_NAME = "INFO_CENTER_POST";

const createPostQuery = () => {
  return new Parse.Query(POST_ENTITY_NAME)
    .equalTo("published", true)
    .exists("topic")
    .descending(["sortWeight", "createdAt"]);
};

export const getTopics = async () => {
  return await new Parse.Query(TOPIC_ENTITY_NAME)
    .descending("sortWeight")
    .find();
};

export const getPostsGroupedByTopic = async ($topics) => {
  const results = [];

  for (const topic of $topics) {
    const postQuery = createPostQuery();
    postQuery.equalTo("topic", topic);
    postQuery.limit(MAX_PREVIEW_POSTS_COUNT);

    const posts = await postQuery.find();

    if (posts.length === 0) {
      continue;
    }

    results.push({
      topic: mapParseTopicToObject(topic),
      posts: posts.map(mapParsePostToObject),
    });
  }

  return results;
};

const cachedResults = new Map();
export const getPostsByTopic = async (topic, options = {}) => {
  options = options || {};

  const currentPageIndex = options?.currentPageIndex;
  const fetchCount = options?.fetchCount;

  const cacheKey = `Topic{${topic.id}}[${currentPageIndex}]`;

  if (cachedResults.has(cacheKey)) {
    return cachedResults.get(cacheKey);
  }

  const $topic = new Parse.Object(TOPIC_ENTITY_NAME);
  $topic.id = topic.id;

  const postQuery = createPostQuery();
  postQuery.equalTo("topic", $topic);

  if (currentPageIndex) {
    postQuery.skip(MAX_INFO_CENTER_PAGE_ELEMENTS * currentPageIndex);
  }

  postQuery.limit(MAX_INFO_CENTER_PAGE_ELEMENTS);

  const posts = await postQuery.find();
  const result = {
    posts: posts.map(mapParsePostToObject),
  };

  if (fetchCount) {
    let postsCount = await postQuery.count();
    result.pageCount = Math.ceil(postsCount / MAX_INFO_CENTER_PAGE_ELEMENTS);
  }

  cachedResults.set(cacheKey, result);
  return result;
};

export const fetchSearchPosts = async (searchString) => {
  let resultPosts = [];

  const postTitleQuery = createPostQuery();
  postTitleQuery.matches("title", searchString, "i");
  const postDescriptionQuery = createPostQuery();
  postDescriptionQuery.matches("description", searchString, "i");

  resultPosts = [
    ...(await postTitleQuery.find()),
    ...(await postDescriptionQuery.find()),
  ];

  return resultPosts
    .map(mapParsePostToObject)
    .filter(
      (post, index, self) => index === self.findIndex((p) => p.id === post.id),
    );
};

export const fetchPromoBannerTopics = async () => {
  const result = {};

  for (let topicKey in INFO_CENTER_BIND_TOPICS) {
    const isRandom = ["NEWS", "PARTNERS_OFFER"].includes(topicKey);
    result[topicKey] = undefined;

    const $topic = new Parse.Object(TOPIC_ENTITY_NAME);
    $topic.id = INFO_CENTER_BIND_TOPICS[topicKey];

    const postQuery = createPostQuery();
    postQuery.equalTo("topic", $topic);
    postQuery.limit(3);

    try {
      const items = await postQuery.find();
      if (items.length) {
        let item = items[0];

        if (isRandom) {
          const randomIndex = Math.floor(Math.random() * items.length);
          item = items[randomIndex];
        }

        result[topicKey] = mapParsePostToObject(item);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return result;
};
