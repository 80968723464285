import React from 'react';
import styled from 'styled-components';
import {
  EuiBasicTable,
} from '@elastic/eui';

const Table = styled(EuiBasicTable)`
    .euiButtonEmpty[data-test-subj='tablePaginationPopoverButton'] {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.colorDarkGray};

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .euiTableHeaderCell .euiTableCellContent__text {
        overflow: visible;
    }

    .euiTableRow.blocked {
        color: ${({ theme }) => theme.colors.colorDarkShade};

        .euiAvatar {
            color: ${({ theme }) => theme.colors.colorDarkShade} !important;
            background-color: ${({ theme }) => theme.colors.colorGrayMiddle} !important;
        }
    }

    .euiTableRowCell {
        color: inherit;
    }

    @media ${({ theme }) => theme.media.phone} {
        .euiTableHeaderMobile {
            display: none;
        }

        .euiAvatar {
            display: none;
        }

        .euiTable.euiTable--responsive {
            .euiTableRowCell {
                &:nth-child(1) {
                    order: 2;
                    max-width: calc(100% - 130px);
                }

                &:nth-child(2) {
                    order: 3;
                    margin-top: -9px;
                    min-width: 130px;
                    flex-basis: 50%;
                }

                &:nth-child(3) {
                    order: 4;
                    min-width: 130px;
                    flex-basis: 40%;
                }

                &:nth-child(4) {
                    order: 1;
                    margin-top: -9px;
                    max-width: 50%;
                }

                &:nth-child(6) {
                    order: 5;
                }

                &:nth-child(7) {
                    order: 8;
                }

                &:nth-child(8) {
                    order: 6;
                }
                &:nth-child(9) {
                    order: 7;
                }
            }

            .euiTableRowCell--hasActions {
                display: none;
            }

            .euiTableRow {
                padding: 8px 16px 12px;
                margin-bottom: 12px;
                box-shadow: 0px 0.9px 4px -1px rgba(109, 152, 184, 0.08), 0px 2.6px 8px -1px rgba(109, 152, 184, 0.06), 0px 5.7px 12px -1px rgba(109, 152, 184, 0.05), 0px 15px 15px -1px rgba(109, 152, 184, 0.04);
            }

            .euiTableRowCell__mobileHeader {
                font-size: 10.5px;
                font-weight: 600;
                color: ${({ theme }) => theme.colors.colorDisabledText};
            }
        }
    }
    @media ${({ theme }) => theme.media.phoneSmall} {
        .euiTable.euiTable--responsive {
            .euiTableRow {
                padding: 8px 8px 12px;
            }
        }
    }
`;

export const EmployeesTable = (props) => {
  const { items, columns, onClickRow, totalAmount, className, getCellProps } = props
  const { pageIndex, onChangePageIndex, pageSize, onChangePageSize } = props.page || {}

  const onTableChange = ({ page = {} }) => {
    const { index: pageIndex, size: pageSize } = page
    onChangePageIndex(pageIndex)
    onChangePageSize(pageSize)
  };

  const pagination = {
    pageIndex: pageIndex || 0,
    pageSize: pageSize || 0,
    totalItemCount: totalAmount || 0,
    pageSizeOptions: [10, 20, 30, 40, 50],
  }

  const getRowProps = (item) => {
    return {
      onClick: () => onClickRow(item),
      className: item.blocked_at && 'blocked'
    }
  }

  return (
    <Table
      className={className}
      items={items || []}
      itemId='id'
      columns={columns}
      cellProps={getCellProps}
      rowProps={getRowProps}
      pagination={pagination}
      onChange={onTableChange}
    />
  )
}

export default EmployeesTable
