import { IS_PROD_ENVIRONMENT } from "app/constants";

export const MAX_PREVIEW_POSTS_COUNT = 3;
export const MAX_INFO_CENTER_PAGE_ELEMENTS = 12;

export const MIN_SEARCH_STRING_LENGTH = 4;

export const EMPTY_TOPIC_ID = null;
export const EMPTY_TOPIC_OBJ = { id: EMPTY_TOPIC_ID, name: "Главная" };

export const INFO_CENTER_STATE = Object.freeze({
  IDLE: "IDLE",
  INITIAL_LOADING: "INITIAL_LOADING",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
});

export const INFO_CENTER_BIND_TOPICS = {
  get NEWS() {
    return IS_PROD_ENVIRONMENT ? "cG1P76WIbY" : "L5mM5SwSij";
  },
  get PARTNERS_OFFER() {
    return IS_PROD_ENVIRONMENT ? "XCaREcxkpz" : "nsHkImzAyD";
  },
  get UPDATES() {
    return IS_PROD_ENVIRONMENT ? "ge59xmxjHX" : "kJaFsUnR05";
  },
  get LEARNING() {
    return IS_PROD_ENVIRONMENT ? "8LymAwJBME" : "Z42svl2rXF";
  },
};
