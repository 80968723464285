export const mapParseTopicToObject = ($topic) => ({
  id: $topic.id,
  name: $topic.get("name"),
  showAllText: $topic.get("showAllText"),
  showDateInCard: $topic.get("showDateInCard"),
});

export const mapParsePostToObject = (rawPost) => {
  return ({
    id: rawPost.id,
    published: rawPost.get("published"),
    title: rawPost.get("title"),
    href: rawPost.get("href"),
    imageSrc: rawPost.get("image")?.url(),
    description: rawPost.get("description"),
    createdAt: rawPost.get("createdAt"),
    topicId: rawPost.get("topic").id
  })
}
