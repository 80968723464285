import styled from "styled-components";
import NotFound from "components/NotFound/NotFound";
import notFoundIcon from "../../assets/images/not-found-page-icon.svg";
import { PrimaryEmptyButton } from "components/Buttons";
import { EuiPageBody } from "@elastic/eui";


const PageBody = styled(EuiPageBody)`
    background-color: ${({ theme }) => theme.colors.colorEmptyShade};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh / var(--page-zoom));
`;

const Undefined = styled(NotFound)`
    color: ${({ theme }) => theme.colors.titleColor};
    padding: 90px 20px 90px;

    .euiText {
        max-width: 430px;
        margin-bottom: 40px;
    }

    @media ${({ theme }) => theme.media.phone} {
        .euiIcon {
            max-width: 56px;
        }

        .euiText {
            margin: 8px auto 18px;
        }
    }
`;

const Container = styled.div`
    color: ${({ theme }) => theme.colors.colorDarkShade};
    font-weight: 600;
`;

const NotFoundPage = () => {
  const onRedirect = () => window.location.href = "/payments/all";
  return (
    <PageBody>
      <Undefined
        icon={notFoundIcon}
        title="Страница не найдена"
        description="Вы зашли по некорректной ссылке, или материал на странице утратил актуальность и был удален."
        color="theme.colors.titleColor"
        footer={
          <Container>
            <PrimaryEmptyButton
              onClick={onRedirect}
            >
              Главная
            </PrimaryEmptyButton>
            / Страница не найдена
          </Container>
        }
      />
    </PageBody>
  );
};

export default NotFoundPage;

