import { StyledSideBarMenuNavList } from "components/SideMenu/styles";
import { EuiListGroupItem } from "@elastic/eui";
import React from "react";

export const SubMenu = ({ menu }) => {
  return (
    <StyledSideBarMenuNavList flush={true} gutterSize="m">
      {menu?.map((item, index) => {
        return (
          <EuiListGroupItem
            label={item.label}
            key={index}
            isActive={item.isActive}
            onClick={item.onClick && item.onClick(item)}
          >
            {item.label}
          </EuiListGroupItem>
        );
      })}
    </StyledSideBarMenuNavList>
  );
};
