import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NestedRoutes from "utils/NestedRoutes";
import SideMenu from "components/SideMenu/SideMenu";
import Header from "components/Header/Header";
import eventsLogo from 'assets/images/logo-round.svg';
import eventCard from 'assets/images/event-card.png';
import styled from 'styled-components';
import {
  EuiPage,
  EuiPageSideBar,
} from "@elastic/eui";
import { breakpoints } from 'utils/useBreakpoints';
import PromoBanner from 'components/PromoBanner/PromoBanner';
import Chatra from 'utils/Chatra';
import userActions from 'store/user/userActions';
import {createSelector} from "reselect";

export const Sidebar = styled(EuiPageSideBar)`
  min-width: 200px;
  width: 200px;
  overflow: overlay;
  padding: 41px 24px 0;
  background: transparent;
  max-height: calc(100vh - ${({$topOffset}) => $topOffset + 48}px);
  top: ${({$topOffset}) => $topOffset + 48}px;
`;

const Page = styled(EuiPage)`
  min-height: calc(100vh / var(--page-zoom));
  padding-top: ${({$topOffset}) => $topOffset + 48}px;
`;

const MainHeader = styled(Header)`
  top: ${({$topOffset}) => $topOffset}px;
`;

const news = [
  {
    id: '01',
    title: 'Профилактические работы',
    badge: 'Новости',
    iconType: eventsLogo,
    time: '12 июня 12:56',
    type: 'news',
    message: 'В канале моментальных выплат с Альфа-Банком платежи массово отклоняются. На стороне банка проводятся техработы. Будем держать вас в курсе.',
    isRead: false,
    primaryAction: 'Подробнее',
  },
  {
    id: '02',
    title: 'Выплаты с Альфа-Банком',
    badge: 'Напоминание',
    iconType: eventsLogo,
    time: '10 мая 16:00',
    type: 'reminder',
    message: 'В канале моментальных выплат с Альфа-Банком платежи массово отклоняются. На стороне банка проводятся техработы. Будем держать вас в курсе.',
    isRead: false,
  },
  {
    id: '03',
    title: 'Предупреждение',
    badge: 'Внимание',
    iconType: eventsLogo,
    badgeColor: '#f2d4d2',
    time: '10 мая 16:00',
    type: 'issue',
    message: 'В канале моментальных выплат с Альфа-Банком платежи массово отклоняются. На стороне банка проводятся техработы. Будем держать вас в курсе.',
    isRead: true,
  },
]

export const singleNews = {
  id: '01',
  title: 'Профилактические работы',
  badge: 'Новости',
  iconType: eventsLogo,
  time: '12 июня 12:56',
  type: 'news',
  message: 'В канале моментальных выплат с Альфа-Банком платежи массово отклоняются. На стороне банка проводятся техработы. Будем держать вас в курсе.',
  isRead: false,
  primaryAction: 'Подробнее',
  image: eventCard
}


const settingsSteps = [{
  title: 'Добавление агрегатора',
  content: 'Мы хорошо понимаем современного предпринимателя, у которого деньги бизнеса могут храниться не только на основном расчетном счете: карты физлиц, наличные, эквайринг и подотчетные средства на руках у сотрудников - все их важно включить в общую систему счетов и учитывать при построении отчетов, чтобы ничего не упустить.',
  status: 'complete',
  state: 'closed'
},
{
  title: 'Настройка способов выплат водителям',
  content: 'Мы хорошо понимаем современного предпринимателя, у которого деньги бизнеса могут храниться не только на основном расчетном счете: карты физлиц, наличные, эквайринг и подотчетные средства на руках у сотрудников - все их важно включить в общую систему счетов и учитывать при построении отчетов, чтобы ничего не упустить.',
  status: 'incomplete',
  state: 'open'
}
]

const { isDesktop } = breakpoints

class BaseLayout extends Component {
  state = {
    isDesktop
  }
  handleResize = () => this.setState({
    isDesktop: window.matchMedia('(min-width: 1366px)').matches,
  })

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps, prevState) {
    const detailsData = this.props.park.details?.data
    const prevDetailsData = prevProps.park.details?.data
    const parkID = detailsData?.park_id
    const bankType = detailsData?.bank_type
    const subscriptionStatus = detailsData?.subscription_status
    const hasParkParams = !!(parkID && bankType && subscriptionStatus)

    const parkParamsMatches = ['park_id', 'bank_type', 'subscription_status'].filter(key => detailsData?.[key] === prevDetailsData?.[key] && key).length
    const ym = window?.ym

    if (hasParkParams && !parkParamsMatches && ym) {
      ym(process.env.REACT_APP_YANDEX_METRICA, 'userParams', {
        park_id: parkID,
        subscription_status: subscriptionStatus,
        bank_type: bankType,
      })
    }
  }

  render() {
    const topOffset = this.props.park.promo.height
    return (
      <>
        <Chatra />
        <MainHeader
          $topOffset={topOffset || 0}
          logout={this.props.logout}
          settingsSteps={settingsSteps}
          news={news}
        />
        <Page
          $topOffset={topOffset || 0}
          paddingSize='none'
        >
          {this.state.isDesktop &&
            <Sidebar
              $topOffset={topOffset || 0}
              paddingSize='none'
              sticky
            >
              <SideMenu />
            </Sidebar>
          }
          <PromoBanner />
          <NestedRoutes routes={this.props.routes} match={this.props.match} />
        </Page>
      </>
    )
  }
}


const makeSelectPark = () => createSelector(
    [state => state.park],
    park => park
);

const mapStateToProps = () => {
  const selectParkMemoized = makeSelectPark();
  return state => ({
    park: selectParkMemoized(state),
  });
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(userActions.logout()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BaseLayout));
