import { createContext, useEffect, useState } from "react";
import { fetchPromoBannerTopics } from "components/InfoCenter/model/service";

const usePromoBanner = () => {
  const [banners, setBanners] = useState({
    NEWS: {},
    PARTNERS_OFFER: {},
    UPDATES: {},
    LEARNING: {},
  });

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      const result = await fetchPromoBannerTopics();
      setBanners(result);
    } catch (error) {
      console.error(`Error [usePromoBanner]: ${error}`);
    }
  };

  return {
    banners,
  };
};

export const PromoBannerContext = createContext({});

export const PromoBannerProvider = ({ children }) => (
  <PromoBannerContext.Provider value={usePromoBanner()}>
    {children}
  </PromoBannerContext.Provider>
);
