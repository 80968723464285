import React from 'react';
import styled from 'styled-components';
import {
  EuiTab,
} from '@elastic/eui';
import { useBreakpoints } from 'utils/useBreakpoints';
import { ContentLoading } from 'components/Loadings';

const Tabs = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  flex-shrink: 0;
  margin: 0 -4px;
  max-width: initial;
  align-items: center;
  min-height: 48px;
  &:before {
    background-color:  ${({ theme }) => theme.colors.colorLightShade} ;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    padding-bottom: 16px;
    max-width: calc(70% + 290px);
    align-items: flex-start;
  }
`;

const Tab = styled(EuiTab)`
  font-weight: 400;
  max-width: calc(70% + 290px);
  &:focus, &:hover {
    background-color: transparent;
    text-decoration: none;
  }
  &.euiTab-isSelected {
    color: ${({ theme }) => theme.colors.colorPrimary};
    font-weight: 600;
    .euiBadge {
      background-color: ${({ theme }) => theme.colors.colorAccentText};
      color: ${({ theme }) => theme.colors.colorEmptyShade};
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    width: 100%;
    padding: 0 4px;
    height: 32px;
    margin: 4px 0;
    font-weight: 500;
    &:after {
      display: none;
    }
    &:focus {
      &:before {
        display: none;
      }
    }
    &.euiTab-isSelected {
      background-color: ${props => props.theme.colors.primaryBackgroundLighter};
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
      color: inherit;
    }
  }
`;

const TabName = styled.span`
  @media ${({ theme }) => theme.media.phone} {
    gap: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const BaseTabs = (props) => {
  const { tabs, selectedTabID, onTabClick, loading, className } = props
  const onChange = (selectedId) => () => onTabClick(tabs.find(({ id }) => selectedId === id))
  const { isMobile } = useBreakpoints()

  if (!isMobile && loading) {
    return (
      <Tabs
        className={className}
      >
        <ContentLoading width='520' height='22' />
      </Tabs>
    )
  }

  return (
    <Tabs
      className={className}
    >
      {tabs.filter(({ name }) => name).map(({ name, id, disabled }) => (
        <Tab
          key={id}
          disabled={disabled}
          isSelected={id === selectedTabID}
          onClick={onChange(id)}
        >
          {loading ? <ContentLoading height='20'/> : <TabName>{name}</TabName>}
        </Tab>
      ))}
    </Tabs>
  )
}
