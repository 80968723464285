import styled from 'styled-components';
import { EuiPageSideBar } from '@elastic/eui';

const BaseSidebar = styled(EuiPageSideBar)`
  min-width: 292px;
  width: 292px;
  padding: 40px 24px;
  max-height: 100%;
`;

export default BaseSidebar