import { InfoCenterListPageProvider } from "containers/InfoCenter/model/model";
import React, { memo } from "react";
import BaseLayout from "containers/layouts/BaseLayout";

export const InfoCenterLayout = memo((props) => {
  return (
    <InfoCenterListPageProvider>
      <BaseLayout {...props} />
    </InfoCenterListPageProvider>
  );
});
