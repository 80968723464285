import { promiseListener } from "store/store";
import receiptActionTypes from "./receiptActionTypes";

export const receiptActions = {

  fetchSno(args) {
    return {
      type: receiptActionTypes.RECEIPT_FETCH_SNO_REQUEST,
      payload: args,
    }
  },
  
  asyncFetchCloudCashbox: promiseListener.createAsyncFunction({
    start: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_REQUEST,
    resolve: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FULFILLED,
    reject: receiptActionTypes.RECEIPT_FETCH_CLOUD_CASHBOX_FAIL,
  }).asyncFunction,

  asyncCreateCloudCashbox: promiseListener.createAsyncFunction({
    start: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_REQUEST,
    resolve: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FULFILLED,
    reject: receiptActionTypes.RECEIPT_CREATE_CLOUD_CASHBOX_FAIL,
  }).asyncFunction,
  
  asyncUpdateCloudCashbox: promiseListener.createAsyncFunction({
    start: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_REQUEST,
    resolve: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_FULFILLED,
    reject: receiptActionTypes.RECEIPT_UPDATE_CLOUD_CASHBOX_FAIL,
  }).asyncFunction,
    
  asyncBlockCloudCashbox: promiseListener.createAsyncFunction({
    start: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_REQUEST,
    resolve: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FULFILLED,
    reject: receiptActionTypes.RECEIPT_BLOCK_CLOUD_CASHBOX_FAIL,
  }).asyncFunction,
}
