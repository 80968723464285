import styled from "styled-components";
import {
  EuiCollapsibleNavGroup,
  EuiListGroup,
  EuiListGroupItem,
  EuiNotificationBadge,
  EuiText,
  EuiTitle,
} from "@elastic/eui";

export const StyledSideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StyledSideBarHeader = styled.div`
  margin-bottom: 42px;
  min-height: 84px;
  @media ${(props) => props.theme.media.phone} {
    margin-bottom: 27px;
  }
`;

export const StyledSideBarHeaderTitle = styled(EuiTitle)`
  width: calc(100% + 10px);
  word-break: break-word;

  &.negative {
    color: ${(props) => props.theme.colors.colorDanger};
  }
`;

export const StyledSideBarAmountBlock = styled.h3`
  font-size: ${(props) => props.fontSize}px !important;
`;

export const StyledSideBarHeaderSubtitle = styled(EuiText)`
  font-weight: 500;
`;

export const StyledSideBarHeaderButtons = styled.div`
  margin-top: 12px;
`;

export const StyledSideBarEmail = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorDarkShade};
  word-break: break-all;
`;

export const StyledSideBarTopNav = styled.div`
  margin: 31px -8px 32px;
`;

export const StyledSideBarBottomNav = styled.div`
  margin: 17px -8px 0;
`;

export const StyledSideBarNavGroup = styled(EuiCollapsibleNavGroup)`
  .euiCollapsibleNavGroup__children {
    padding: 0;
  }

  .euiListGroup + .euiListGroup .euiListGroupItem__button,
  & .euiListGroup .euiListGroup .euiListGroupItem .euiListGroupItem__button {
    font-weight: 500;
    font-size: 14px;
    color: #69707d;
    padding-right: 0;
  }

  & .euiListGroup .euiListGroupItem {
    &__button .menu-icon {
      color: rgba(105, 112, 125, 1);
    }
  }
`;

export const StyledSideBarMenuNavList = styled(EuiListGroup)`
  .euiListGroupItem__button {
    font-weight: 700;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .euiListGroupItem--medium + .euiListGroupItem--medium {
    margin-top: 0;
  }

  .euiListGroupItem {
    .euiIcon:first-child {
      margin-right: 8px;
    }

    a {
      color: ${(props) => props.theme.colors.textColor};
      font-weight: 700;
    }

    &:hover,
    &:focus {
      .euiListGroupItem__button {
        text-decoration: none;
      }
    }

    &.euiListGroupItem-isActive {
      background-color: transparent;

      &:hover {
        background-color: rgba(211, 218, 230, 0.25);
      }

      .euiListGroupItem__button {
        color: ${(props) => props.theme.colors.colorPrimary};
      }
    }
  }

  &
    .euiListGroup
    .euiListGroupItem.euiListGroupItem-isActive
    .euiListGroupItem__button {
    color: ${(props) => props.theme.colors.colorPrimary};
  }

  & .euiListGroup {
    .euiListGroupItem {
      padding-left: 23px;

      .euiListGroupItem__label {
        white-space: normal;
      }
    }
  }

  .euiListGroupItem__label {
    width: 100%;
  }

  .rotate90 {
    transform: rotate(90deg);
  }

  svg + svg {
    order: 3;
  }

  .navExit {
    a {
      color: ${(props) => props.theme.colors.colorDarkShade};
    }
  }
`;

export const StyledSideBarPaymentsMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export const StyledSideBarInsuranceMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
`;

export const StyledSideBarPaymentsQuantity = styled(EuiNotificationBadge)`
  height: 20px;
  width: 28px;
  line-height: 21px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 3px;
`;

export const StyledSideBarInsuranceNewItem = styled(EuiNotificationBadge)`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  background: var(--viz-behind-text-eui-color-vis-2-behind-text, #ee789d);
`;

export const StyledSideBarCents = styled.span`
  font-size: 14px;
`;

export const StyledSideBarListGroupItem = styled(EuiListGroupItem)``;

export const StyledSideBarTopSection = styled.div`
  flex: 2;
`;

export const StyledSideBarBottomSection = styled.div`
  flex: 1;
`;

export const StyledSideBarVersion = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.colorDarkShade};
`;

export const StyledSideBarStatus = styled.span`
  font-weight: 600;
`;

export const StyledSideBarDanger = styled(StyledSideBarStatus)`
  color: ${({ theme }) => theme.colors.colorDanger};
`;
