import styled from 'styled-components';
import { EuiPageBody, EuiPageContentBody } from '@elastic/eui';

const PageBody = styled(EuiPageBody)`
  padding: 40px 32px 44px;
  background-color: ${({ theme }) => theme.colors.colorEmptyShade};
  @media ${({ theme }) => theme.media.phone} {
    padding: 24px 16px 28px;
  }
`;

export const BasePageBody = (props) => {
  const { className, children } = props
  return (
    <PageBody
      className={className}
    >
      <EuiPageContentBody restrictWidth={'100%'}>
        {children}
      </EuiPageContentBody>
    </PageBody>
  )
}

export default BasePageBody
