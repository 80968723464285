import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  EuiDatePicker,
  EuiIcon,
  EuiLoadingSpinner,
  EuiFieldText,
  EuiOutsideClickDetector,
  EuiFormRow
} from '@elastic/eui';
import moment from 'moment';

export const DateWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  width: 100%;
  align-items: center;
`;

export const Icon = styled(EuiIcon)``;

const CalendarIcon = styled(Icon)`
  margin-left: 13px;
`;

export const DateInput = styled(EuiFieldText)`
  box-shadow: none;
  text-align: center;
  padding: 0 8px;
  flex: 1;
  &:focus {
    border: none;
    box-shadow: none;
  }
  &:disabled {
    box-shadow: none;
  }
`;

const DateInputEnd = styled(DateInput)`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const DatePicker = styled.div`
  position: relative;
  margin: auto;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  z-index: 9;
  right: 0;
`;

export const RangeDatePicker = (props) => {
  const {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    dateFormat,
    className,
    initialPlaceholder,
    inputPlaceholder,
    bothDatesInitial,
    label,
    error,
    caption,
    hideIcon,
    loading,
    showTimeSelectOnly,
    startMinDate,
    startMaxDate,
    endMinDate,
    endMaxDate,
  } = props

  const [startInputValue, setStartInputValue] = useState('')
  const [endInputValue, setEndInputValue] = useState('')

  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false)
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false)

  const errors = error ? [error] : []
  const stringDateFormat = dateFormat || 'DD/MM/YYYY'
  const hasDate = startDate || endDate
  const isInvalid = !!startDate && !!endDate && startDate > endDate && !!errors
  const isValidDate = value => moment(value, stringDateFormat, true).isValid()

  const compareDateStrings = (startDate, endDate) => moment(startDate, stringDateFormat) > moment(endDate, stringDateFormat)

  const onOpenStartDatePicker = () => setIsStartDatePickerOpen(true)
  const onCloseStartDatePicker = () => setIsStartDatePickerOpen(false)
  const onOpenEndDatePicker = () => setIsEndDatePickerOpen(true)
  const onCloseEndDatePicker = () => setIsEndDatePickerOpen(false)

  useEffect(() => {
    onCloseStartDatePicker()
    onCloseEndDatePicker()
    setStartInputValue(startDate ? moment(startDate).format(stringDateFormat) : '')
    setEndInputValue(endDate ? moment(endDate).format(stringDateFormat) : '')
  }, [stringDateFormat, startDate, endDate])

  const onChangeStartInput = e => {
    const value = e.target.value || ''
    setStartInputValue(value)
    if (!value) {
      onChangeStartDate(null)
    }
    if (isValidDate(value) && !compareDateStrings(value, endInputValue)) {
      onChangeStartDate(moment(value, stringDateFormat))
    }
  }

  const onChangeStartDatePicker = value => onChangeStartDate(value)

  const onBlurStart = e => {
    const value = e.target.value
    if (!isValidDate(value) || compareDateStrings(value, endInputValue)) {
      setStartInputValue(startDate ? moment(startDate).format(stringDateFormat) : '')
    }
  }

  const onChangeEndInput = e => {
    const value = e.target.value || ''
    setEndInputValue(value)
    if (!value) {
      onChangeEndDate(null)
    }
    if (isValidDate(value) && !compareDateStrings(startInputValue, value)) {
      onChangeEndDate(moment(value, stringDateFormat))
    }
  }

  const onChangeEndDatePicker = value => onChangeEndDate(value)

  const onBlurEnd = e => {
    const value = e.target.value
    if (!isValidDate(value) || compareDateStrings(startInputValue, value)) {
      setEndInputValue(endDate ? moment(endDate).format(stringDateFormat) : '')
    }
  }

  const inputStart = (
    <DateInput
      value={startInputValue}
      onClick={onOpenStartDatePicker}
      onChange={onChangeStartInput}
      isInvalid={isInvalid}
      placeholder={endDate || bothDatesInitial ? inputPlaceholder : initialPlaceholder}
      disabled={loading}
      onBlur={onBlurStart}
    />
  )

  const inputEnd = (
    <DateInputEnd
      value={endInputValue}
      onClick={onOpenEndDatePicker}
      onChange={onChangeEndInput}
      isInvalid={isInvalid}
      placeholder={inputPlaceholder}
      disabled={loading}
      onBlur={onBlurEnd}
    />
  )

  return (
    <EuiFormRow
      label={label}
      fullWidth
      isInvalid={isInvalid}
      error={errors}
      helpText={caption}
      className={className}
    >
      <DateWrapper
        className='dateWrapper'
      >
        {!hideIcon && <CalendarIcon type='calendar' />}
        <DatePicker>
          <EuiOutsideClickDetector
            onOutsideClick={onCloseStartDatePicker}
          >
            <div>
              {inputStart}
              <DatePickerWrapper
                className='startDatePickerWrapper'
              >
                {isStartDatePickerOpen &&
                  <EuiDatePicker
                    selected={startDate}
                    onChange={onChangeStartDatePicker}
                    startDate={startDate}
                    endDate={endDate}
                    isInvalid={isInvalid}
                    disabled={loading}
                    aria-label="Start date"
                    adjustDateOnChange={false}
                    minDate={startMinDate}
                    maxDate={startMaxDate}
                    autoFocus={false}
                    showTimeSelectOnly={showTimeSelectOnly}
                    inline
                  />
                }
              </DatePickerWrapper>
            </div>
          </EuiOutsideClickDetector>
        </DatePicker>
        {(hasDate || bothDatesInitial) &&
          <>
            {loading ? <EuiLoadingSpinner size='m' /> : <Icon type='sortRight' />}
            <DatePicker>
              <EuiOutsideClickDetector
                onOutsideClick={onCloseEndDatePicker}
              >
                <div>
                  {inputEnd}
                  <DatePickerWrapper
                    className='endDatePickerWrapper'
                  >
                    {isEndDatePickerOpen &&
                      <EuiDatePicker
                        selected={endDate}
                        onChange={onChangeEndDatePicker}
                        startDate={startDate}
                        endDate={endDate}
                        isInvalid={isInvalid}
                        disabled={loading}
                        aria-label="End date"
                        adjustDateOnChange={false}
                        minDate={endMinDate}
                        maxDate={endMaxDate}
                        autoFocus={false}
                        showTimeSelectOnly={showTimeSelectOnly}
                        inline
                      />
                    }
                  </DatePickerWrapper>
                </div>
              </EuiOutsideClickDetector>
            </DatePicker>
          </>
        }
      </DateWrapper>
    </EuiFormRow>
  )
}

export default RangeDatePicker