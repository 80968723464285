export const transactionReasons = {
  "initial_driver": {
    id: 'initial_driver',
    label: 'label',
    translate: 'Создание аккаунта'
  },
  "taxi_ride_driver": {
    id: 'taxi_ride_driver',
    label: 'label',
    translate: 'Перевод водителю за поездку'
  },
  "taxi_ride_aggregator_commission": {
    id: 'taxi_ride_aggregator_commission',
    label: 'label',
    translate: 'Комиссия автопарка и агрегатора'
  },
  "taxi_ride_park": {
    id: 'taxi_ride_park',
    label: 'label',
    translate: 'Перевод автопарку за поездку'
  },
  "park_driver_withdraw": {
    id: 'park_driver_withdraw',
    label: 'Вывод средств',
    translate: 'Вывод на карту'
  },
  "park_driver_withdraw_commission": {
    id: 'park_driver_withdraw_commission',
    label: 'Комиссия за вывод',
    translate: 'Комиссия за вывод средств'
  },
  "park_deposit_pay": {
    id: 'park_deposit_pay',
    label: 'label',
    translate: 'Оплата депозита автопарком'
  },
  "park_mozen_deposit_pay": {
    id: 'park_mozen_deposit_pay',
    label: 'label',
    translate: 'Пополнение депозита парка Мозеном'
  },
  "park_subscription_pay": {
    id: 'park_subscription_pay',
    label: 'label',
    translate: 'Оплата в маркетплейсе бонусами'
  },
  "market_driver_pay": {
    id: 'market_driver_pay',
    label: 'label',
    translate: 'Оплата водителем в маркетплейсе',
  },
  "market_bonus_pay": {
    id: 'market_bonus_pay',
    label: 'label',
    translate: 'Оплата в маркетплейсе бонусами'
  },
  "market_cash_pay": {
    id: 'market_cash_pay',
    label: 'label',
    translate: 'Оплата в маркетплейсе картой'
  },
  "mozen_bonus_withdraw": {
    id: 'mozen_bonus_withdraw',
    label: 'label',
    translate: 'Вывод денег с бонусного счета'
  },
  "mozen_bonus_topup": {
    id: 'mozen_bonus_topup',
    label: 'label',
    translate: 'Пополнение бонусного счета'
  },
  "taxometer_manual_topup": {
    id: 'taxometer_manual_topup',
    label: 'Пополнение баланса',
    translate: 'Пополнение баланса водителя'
  },
  "taxometer_manual_withdraw": {
    id: 'taxometer_manual_withdraw',
    label: 'label',
    translate: '???'
  },
  "taxometer_referral_topup": {
    id: 'taxometer_referral_topup',
    label: 'Реферальная программа',
    translate: 'Платеж за приглашенного друга'
  },
  "driver_fuel_card_topup": {
    id: 'driver_fuel_card_topup',
    label: 'label',
    translate: 'Пополнение топливной карты'
  },
  "taximeter_topup": {
    id: 'taximeter_topup',
    label: 'label',
    translate: 'Пополнение баланса водителя'
  },
  "taximeter_refund": {
    id: 'taximeter_refund',
    label: 'label',
    translate: 'Возврат пополнения баланса'
  },
  "driver_withdraw_fuel": {
    id: 'driver_withdraw_fuel',
    label: 'Оплата за топливо',
    translate: 'Оплата за топливо'
  },
  "driver_withdraw_fuel_correction": {
    id: 'driver_withdraw_fuel_correction',
    label: 'Корректировка оплаты за топливо',
    translate: 'Корректировка оплаты за топливо'
  },
}
